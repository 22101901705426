<template xmlns="http://www.w3.org/1999/html">
  <div v-if="!modalOpen" @click="onMenu" class="overflow-x-hidden fixed top-0 left-0 h-screen w-screen bg-black/50 flex justify-end z-20">
    <div @click.stop class="w-screen md:w-[400px] md:m-betweenElements rounded-none md:rounded-md bg-grey-light p-outerFrameBorder md:border md:border-primary flex flex-col">
      <div class="flex flex-row mb-8">
        <button class="hover:text-primary transition-colors text-bodyLargeBold text-left flex flex-row items-center" @click="onLogout">
          <phosphor-icon class="mr-3" icon="lock-key" />Ausloggen
        </button>
        <button class="hover:cursor-pointer text-primary ml-auto" @click="onMenu">
          <base-icon size="text-iconSizeLarge" @click="onMenu">close</base-icon>
        </button>
      </div>

      <div class="overflow-y-auto flex-grow">
        <div class="menu-item" @click="router.push({name: routeNames.DASHBOARD}); onMenu()">Dashboard<base-icon class="ml-auto">space_dashboard</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.CUSTOMERS}); onMenu()" v-if="TenantSettings.isProjectOriented()">Kunden<base-icon class="ml-auto">groups</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.PROJECTS}); onMenu()">{{ TenantSettings.isProjectOriented() ? 'Alle Projekte' : 'Kunden' }}<base-icon class="ml-auto">content_paste_search</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.CATEGORIES_ROOT}); onMenu()" v-if="KeycloakService.isManager()">{{ TenantSettings.isTransport() ? 'Preisvorlagen' : 'Kategorien' }}<base-icon class="ml-auto">category</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.EDIT_COMPANY}); onMenu()" v-if="KeycloakService.isAdmin()">Firma<base-icon class="ml-auto">factory</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.PRESETS_AND_TYPES}); onMenu()" v-if="KeycloakService.isAdmin()">{{ TenantSettings.isTransport() ? 'Einheiten und Typen' : 'Vorlagen und Typen' }}<base-icon class="ml-auto">format_list_numbered</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.PROFILE}); onMenu()">Mein Profil<base-icon class="ml-auto">person</base-icon></div>
        <div class="menu-item" @click="router.push({name: routeNames.SETTINGS}); onMenu()" v-if="KeycloakService.isAdmin()">Einstellungen<base-icon class="ml-auto">settings</base-icon></div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import { KeycloakService } from '@/service/keycloakService'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import { ref } from 'vue'
import { TenantSettings } from '@/stores/TenantSettings'
import BaseIcon from '@/components/base/BaseIcon.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const emits = defineEmits(['deactivate-menu']);
const router = useRouter();

const confirmDeletionModal = ref(ConfirmationModal);
const modalOpen = ref(false);

const onMenu = () => {
  emits('deactivate-menu');
};

function onLogout() {
  sessionStorage.clear();
  KeycloakService.logout();
}

</script>

