<template>
  <div v-if="isVisible" class="modal-container">
    <div class="modal-small-content w-modalWidth" :class="{'cursor-pointer': recording}" @click="transcribe">

      <!-- Title-->
      <div class="p-8 text-title">Transkribieren</div>

      <!-- Description -->
      <div class="flex flex-col items-center justify-center w-full h-auto">
        <template v-if="requesting">
          <spinner v-if="requesting" />
          <p v-if="requesting" class="text-bodyMediumBold">Transkribierung...</p>
        </template>
        <template v-else-if="recording">
          <button>
            <base-icon size="text-iconSizeGiant" :class="{'recording': recording}">
              {{ recording ? 'mic' : 'mic_none' }}
            </base-icon>
          </button>
          <p class="text-bodyMediumBold">Klicken um Aufnahme zu stoppen</p>
        </template>
        <div v-else-if="textDecision" class="flex md:flex-row flex-col md:w-auto w-full md:mt-11">
          <base-button type="icon" look="secondary" class="mb-betweenElements md:mb-0 md:mr-betweenElements" @click="onAppendText">
            <base-icon>menu_open</base-icon>
            Text anfügen
          </base-button>
          <base-button type="icon" look="secondary" @click="onReplaceText">
            <base-icon>cached</base-icon>
            Text ersetzen
          </base-button>
        </div>
      </div>


      <!-- Buttons -->
      <div class="w-auto absolute inset-x-0 bottom-0" @click.stop>
        <base-button class="m-8" look="secondary" @click="closeModal">
          Abbrechen
        </base-button>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import Spinner from '@/components/generel/Spinner.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'

const props = defineProps<{
  existingText: string | undefined
}>();

const isVisible = ref(false);
const recording = ref(true);
const requesting = ref(false);
const textDecision = ref(false);
const transcription = ref('');
const emits = defineEmits(['on-transcribed']);

const mediaRecorder = ref<MediaRecorder | null>(null);
const audioChunks = ref<Blob[]>([]);

const $toast = useToast();

const openModal = (): void => {
  isVisible.value = true;
  recording.value = true;
  requesting.value = false;
  textDecision.value = false;
  audioChunks.value = [];
  startRecording();
};

const closeModal = (): void => {
  killAudio();
  isVisible.value = false;
  audioChunks.value = [];
  requesting.value = false;
  textDecision.value = false;
};

function transcribe() {
  recording.value = false;
  requesting.value = true;
  stopRecording();
}

function onTranscribed(transcriptionText: string) {
  if (props.existingText && props.existingText != '') {
    requesting.value = false;
    recording.value = false;
    transcription.value = transcriptionText;
    textDecision.value = true;
  } else {
    emits('on-transcribed', transcriptionText);
    closeModal();
  }
}

function onAppendText() {
  const text = props.existingText?.endsWith('.') ? `${props.existingText} ${transcription.value}` : `${props.existingText}. ${transcription.value}`
  emits('on-transcribed', text);
  closeModal();
}

function onReplaceText() {
  emits('on-transcribed', transcription.value);
  closeModal();
}

async function startRecording() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.value = new MediaRecorder(stream);
    mediaRecorder.value.ondataavailable = (event) => {
      audioChunks.value.push(event.data);
    };
    mediaRecorder.value.start();
  } catch (err) {
    $toast.info('Um die Transkribierungs-Funktionalität zu nutzen muss zugriff zum Mikrofon gewährt werden')
    console.error('Error accessing microphone:', err);
    closeModal();
  }
}

function stopRecording() {
  if (mediaRecorder.value) {
    mediaRecorder.value.stop();
    mediaRecorder.value.onstop = sendAudio;
    killAudio();
  } else {
    console.error('No MediaRecorder');
  }
}

async function sendAudio() {
  const audioBlob = new Blob(audioChunks.value, { type: 'audio/wav' });
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.wav');

  try {
    const response = await API.postMultiPart<string>(PathSegment.TRANSCRIBE, formData);
    if (response.key == ResponseKey.OK && response.data) {
      onTranscribed(response.data.replace(/[\r\n]+/g, ''));
    } else {
      $toast.error(`Transkribierung fehlgeschlagen: ${response.message}`);
      console.error(`Transcription failed ${response.message}`);
    }
  } catch (error) {
    $toast.error(`Transkribierung fehlgeschlagen: ${Utils.getError(error)}`);
  }
}

function killAudio() {
  if (mediaRecorder.value) {
    mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
  }
}

onUnmounted(() => {
  killAudio();
});

defineExpose({
  openModal,
  closeModal
});

</script>

<style scoped>
@keyframes colorChange {
  0%, 100% {
    color: lightgray;
  }
  50% {
    color: red;
  }
}

.recording {
  animation: colorChange 2s infinite;
}
</style>