import { de } from '@formkit/i18n'


export enum ReportType {
    WORK = 'WORK',
    MATERIAL = 'MATERIAL'
}

export enum OfferStatus {
    OPEN = 'OPEN',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED'
}

export enum Currency {
    CHF = 'CHF',
    EUR = 'EUR',
    USD = 'USD',
}

export interface BasicType {
    id: string,
    name: string
}

export interface PaymentType extends BasicType {
}

export interface DiscountType extends BasicType {
    standardAmount: number,
    relative: boolean,
    hidden: boolean,
    deduction: boolean,
}

export interface Status extends BasicType {
    priority: number,
    displayColor: string,
    locking: boolean
}

export interface CategoryType extends BasicType {
    reportType: ReportType,
    displayColor: string
}

export interface AbstractUnit extends BasicType {
    abbreviation: string,
    hidden: boolean
}

export class Unit implements BasicType {
    public id: string;
    public name: string;
    public abbrev: string;
    public hidden: boolean;

    constructor(id: string, name: string, abbreviation: string, hidden: boolean) {
        this.id = id;
        this.name = name;
        this.abbrev = abbreviation;
        this.hidden = hidden;
    }

    public abbreviation(): string {
        return this.abbrev.replace(/\\u[\dA-F]{4}/gi, function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }
}

export class ImmutableUnit extends Unit {
    public isCurrency : boolean;

    constructor(id: string, name: string, abbreviation: string, currency: boolean) {
        super(id, name, abbreviation, false);
        this.isCurrency = currency;
    }
}

export class Type {

    private static paymentTypes = new Map<string, PaymentType>;
    private static discountTypes = new Map<string, DiscountType>;
    private static statuses = new Map<string, Status>;
    private static categoryTypes = new Map<string, CategoryType>;
    private static units = new Map<string, Unit>;

    public static initValues(collection: TypesCollection) {
        if (!this.mapsAreEmpty()) return;
        this.paymentTypes = this.listToMap(collection.paymentTypes);
        this.discountTypes = this.listToMap(collection.discountTypes);
        this.statuses = this.listToMap(collection.statuses);
        this.categoryTypes = this.listToMap(collection.categoryTypes);
        this.units = this.listToMap(collection.units.map(unit => new Unit(unit.id, unit.name, unit.abbreviation, unit.hidden)));
    }

    public static updateUnits(units: AbstractUnit[]) {
        this.units = this.listToMap(units.map(unit => new Unit(unit.id, unit.name, unit.abbreviation, unit.hidden)));
    }

    public static updateDiscountTypes(discountTypes: DiscountType[]) {
        this.discountTypes = this.listToMap(discountTypes);
    }

    public static getAllPaymentTypes() {
        return [...this.paymentTypes.values()];
    }

    public static getPaymentType(id: string): PaymentType {
        return this.getFromMap(this.paymentTypes, id);
    }

    public static getAllDiscountTypes() {
        return [...this.discountTypes.values()].filter(d => !d.hidden);
    }

    public static getAllDiscountTypesUnfiltered() {
        return [...this.discountTypes.values()];
    }

    public static getDiscountType(id: string): DiscountType {
        return this.getFromMap(this.discountTypes, id);
    }

    public static getAllStatuses() {
        return [...this.statuses.values()];
    }

    public static getAllStatusesOrderByPriority() {
        return [...this.statuses.values()].sort((a, b) => {
            if (a.priority < b.priority) return 1;
            if (a.priority == b.priority) return 0;
            return -1;
        });
    }

    public static getStatus(id: string): Status {
        return this.getFromMap(this.statuses, id);
    }

    public static getAllCategoryTypes() {
        return [...this.categoryTypes.values()];
    }

    public static getCategoryType(id: string): CategoryType {
        return this.getFromMap(this.categoryTypes, id);
    }

    public static getAllUnits() {
        return [...this.units.values()].filter(u => !u.hidden);
    }

    public static getAllUnitsUnfiltered() {
        return [...this.units.values()];
    }

    public static getUnit(id: string): Unit {
        return this.getFromMap(this.units, id);
    }

    private static getFromMap<T extends BasicType>(map: Map<string, T>, id: string): T {
        const element = map.get(id);
        if (!element) {
            throw new Error(`Type with id ${id} not found`);
        }
        return element;
    }

    private static listToMap<T extends BasicType>(list: T[]): Map<string, T> {
        const map = new Map<string, T>();
        list.forEach(element => map.set(element.id, element));
        return map;
    }

    private static isEmpty(map: Map<string, BasicType>): boolean {
        return map.size == 0;
    }

    private static mapsAreEmpty(): boolean {
        return    (this.isEmpty(this.paymentTypes)
                && this.isEmpty(this.discountTypes)
                && this.isEmpty(this.statuses)
                && this.isEmpty(this.categoryTypes)
                && this.isEmpty(this.units));
    }

}

export interface TypesCollection {
    paymentTypes: PaymentType[],
    discountTypes: DiscountType[],
    statuses: Status[],
    categoryTypes: CategoryType[],
    units: AbstractUnit[],
}

export function offerStatusColor(status: OfferStatus) {
    if (!status) return '#FFFFFF';
    switch (status) {
        case OfferStatus.OPEN: return '#F5A142'
        case OfferStatus.ACCEPTED: return '#8BC93A'
        case OfferStatus.REJECTED: return '#B72121'
    }
}

export function translateOfferStatus(status: OfferStatus) {
    if (!status) return '';
    switch (status) {
        case OfferStatus.OPEN: return 'Offen'
        case OfferStatus.ACCEPTED: return 'Akzeptiert'
        case OfferStatus.REJECTED: return 'Abgelehnt'
    }
}

export function offerStatusIsLocking(status: OfferStatus) {
    if (!status) return true;
    switch (status) {
        case OfferStatus.OPEN: return false
        case OfferStatus.ACCEPTED: return true
        case OfferStatus.REJECTED: return true
    }
}
