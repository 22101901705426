import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import type { Page } from '@/model/Page'
import type { ReportPosition } from '@/model/ReportPosition'
import { type ReportType } from '@/model/Type'

const $toast = useToast();

export async function getReportPage(invoiceId: string, pageSize: number, offset: number, type: ReportType) {
  try {
    const segment = type == 'WORK' ? PathSegment.WORK_REPORT_POSITIONS : PathSegment.MATERIAL_REPORT_POSITIONS
    const response = await API.getWithParameters<Page<ReportPosition>>(
      PathSegment.INVOICES,
      invoiceId,
      segment,
      new Map<string, string>([['pageSize', `${pageSize}`], ['offset', `${offset}`]])
    );
    if (response.key == ResponseKey.OK) return response.data;
    $toast.error(`Arbeitsrapport konnte nicht geladen werden: ${response.message}`);
  } catch (error) {
    $toast.error(`Arbeitsrapport konnte nicht geladen werden: ${Utils.getError(error)}`);
  }
}