<template>
  <base-container>

    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <phosphor-icon icon="currency-dollar" />
        </div>
        Einheiten
      </base-heading>
      <FormKit type="checkbox" label="Verborgene Anzeigen" class="mb-auto rounded-lg" v-model="showHidden" />
    </div>


    <FormKit type="select" label="Einheit" :options="unitOptions" v-model="selectedUnitId" />
    <FormKit type="text" label="Name*" validation="required" v-model="name" />
    <FormKit type="text" label="Abkürzung (max. 6 Zeichen)*" validation="required" v-model="abbreviation" />

    <div class="flex md:flex-row flex-col">
      <base-button class="md:mr-auto md:mb-0 mb-8 md:w-buttonXLarge" @click="onSubmit">
        <base-icon v-if="!requesting" class="mr-5">save</base-icon>
        <spinner-small v-if="requesting" />
        <div v-else>{{selectedUnitId == '' ? 'Erstellen' : 'Speichern'}}</div>
      </base-button>
      <base-button class="md:ml-auto md:w-buttonXLarge" look="secondary" v-if="selectedUnitId != ''" @click="Type.getUnit(selectedUnitId).hidden ? onSetVisibility(true) :  openModal()">
        <base-icon class="mr-5">{{ Type.getUnit(selectedUnitId).hidden ? 'visibility' : 'visibility_off' }}</base-icon>
        {{ Type.getUnit(selectedUnitId).hidden ? 'Wiederherstellen' : 'Verbergen' }}
      </base-button>
    </div>

    <confirmation-modal ref="confirmDeletionModal"
                        title="Einheit verbergen"
                        confirm-text="Verbergen"
                        @on-confirm="onSetVisibility(false)">
      Soll die Einheit wirklich verborgen werden?
    </confirmation-modal>

  </base-container>
</template>

<script setup lang="ts">

import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { ref, watch } from 'vue'
import { Utils } from '@/client/utils'
import { Type, type TypesCollection } from '@/model/Type'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const showHidden = ref(false);

const unitOptions = ref(getOptions());
const selectedUnitId = ref('');
const name = ref('');
const abbreviation = ref('');

const requesting = ref(false);
const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

async function onSubmit() {
  if (selectedUnitId.value == '') onCreateUnit();
  else onUpdateUnit();
}

function getOptions() {
  return showHidden.value
    ? [{label: 'Neue Einheit', value: ''}, ...Type.getAllUnitsUnfiltered().map(u => {
      return {label: `${u.name}${u.hidden ? ' (verborgen)' : ''}`, value: u.id}
    })]
    : Utils.toSelectOptions(Type.getAllUnits(), 'Neue Einheit');
}

async function onCreateUnit() {
  requesting.value = true;
  const response = await API.post<TypesCollection>(PathSegment.TYPES_UNITS, {
    id: '',
    name: name.value,
    abbreviation: abbreviation.value,
    hidden: false
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.CREATED && response.data) {
    const ids = new Set(Type.getAllUnits().map(u => u.id));
    const newUnit = response.data.units.filter(u => !u.hidden).find(unit => !ids.has(unit.id));
    const newId = newUnit ? newUnit.id : '';

    Type.updateUnits(response.data.units);
    unitOptions.value = getOptions();
    $toast.success(`Einheit erstellt`);
    selectedUnitId.value = newId;
  } else {
    $toast.error("Erstellen der Einheit fehlgeschlagen");
  }
}

async function onUpdateUnit() {
  const selectedUnit = Type.getUnit(selectedUnitId.value);
  requesting.value = true;
  const response = await API.put<TypesCollection>(PathSegment.TYPES_UNITS, {
    id: selectedUnitId.value,
    name: name.value,
    abbreviation: abbreviation.value,
    hidden: selectedUnit.hidden
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.UPDATED && response.data) {
    Type.updateUnits(response.data.units);
    unitOptions.value = getOptions();
    $toast.success(`Einheit aktuallisiert`);
  } else {
    $toast.error("Aktuallisieren der Einheit fehlgeschlagen");
  }
}

async function onSetVisibility(visible: boolean) {
  const selectedUnit = Type.getUnit(selectedUnitId.value);
  requesting.value = true;
  const response = await API.put<TypesCollection>(PathSegment.TYPES_UNITS, {
    id: selectedUnit.id,
    name: selectedUnit.name,
    abbreviation: selectedUnit.abbrev,
    hidden: !visible
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.UPDATED && response.data) {
    selectedUnitId.value = '';
    if (visible) selectedUnitId.value = selectedUnit.id;
    Type.updateUnits(response.data.units);
    unitOptions.value = getOptions();
    $toast.success(`Einheit ${visible ? 'wiederhergestellt' : 'verborgen'}`);
  } else {
    $toast.error(`${visible ? 'Wiederherstellen' : 'Verbergen'} der Einheit fehlgeschlagen`);
  }
}

watch(() => selectedUnitId.value, newVal => {
  if (!newVal || newVal == '') {
    name.value = '';
    abbreviation.value = '';
  } else {
    const unit = Type.getUnit(newVal);
    name.value = unit.name;
    abbreviation.value = unit.abbreviation()
  }
}, {
  immediate: true
});

watch(() => showHidden.value, newVal => {
  unitOptions.value = getOptions();
  selectedUnitId.value = '';
}, {
  immediate: true
});

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

</script>