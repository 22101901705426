import pdfMake from 'pdfmake/build/pdfmake'

import { DateConverter } from '@/client/DateConverter'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { Type } from '@/model/Type'
import type { PdfFormatInfo } from '@/model/PdfFormatInfo'
import { API } from '@/client/axios'
import type Customer from '@/model/Customer'
import type Company from '@/model/Company'
import { TenantFields } from '@/stores/TenantFields'
import { CustomFieldEntity, CustomFieldType, type CustomFieldValue } from '@/model/CustomField'
import type OfferInfo from '@/model/OfferInfo'
import type { OfferPosition } from '@/model/OfferPosition'
import { TenantSettings } from '@/stores/TenantSettings'

(pdfMake as any).fonts = {
  Roboto: {
    normal: `${API.apiBaseUrl}fonts/Roboto-Regular.ttf`,
    bold: `${API.apiBaseUrl}fonts/Roboto-Medium.ttf`,
    italics: `${API.apiBaseUrl}fonts/Roboto-Italic.ttf`,
    bolditalics: `${API.apiBaseUrl}fonts/Roboto-MediumItalic.ttf`,
  }
};

export class OfferPdfService {

  static readonly COL_MARGIN = 2;

  static async generateOffer(offerInfo: OfferInfo, pdfFormatInfo: PdfFormatInfo) {

    try {
      const docName = `offerte_${DateConverter.getCurrentLocalDate().replace(/\./g, '_')}_${offerInfo.project.name.toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_')}.pdf`;

      const docDefinition: any = {
        info: {
          title: docName
        },
        content: [],
        defaultStyle: {
          columnGap: 20,
        },
        footer: (currentPage: any, pageCount: any) => {
          return {
            text: `${currentPage} / ${pageCount}`,
            alignment: 'right',
            fontSize: 9,
            margin: [0, 10, 20, 0]
          };
        },
      };

      if (offerInfo.base64Logo) docDefinition.content.push(this.getLogo(offerInfo.base64Logo, offerInfo.settings.logoHeight, offerInfo.settings.distanceTopLogo));
      docDefinition.content.push(this.getAddresses(offerInfo.company, offerInfo.customer, offerInfo.settings.distanceLogoAddress, offerInfo.settings.distanceBetweenAddresses));
      docDefinition.content.push(this.standardText(`${offerInfo.company.address.city}, ${DateConverter.getCurrentLocalDate()}`, 0, 20))
      docDefinition.content.push({ text: offerInfo.settings.title, fontSize: 13, margin: [0, 0, 0, 10] });
      if (offerInfo.settings.header) docDefinition.content.push(this.standardText(offerInfo.settings.header));
      docDefinition.content.push(this.getTableTitle(offerInfo.settings.workTableTitle, pdfFormatInfo.pageBreakBeforeWork ? 0 : pdfFormatInfo.marginBeforeWork, pdfFormatInfo.pageBreakBeforeWork, offerInfo.calculation.workPositions.length > 0));
      docDefinition.content.push(this.getOfferPositionTable(offerInfo.calculation.workPositions, offerInfo.calculation.totals.workTotal, pdfFormatInfo.marginAfterWork));
      docDefinition.content.push(this.getTableTitle(offerInfo.settings.materialTableTitle.split(';;;')[0], pdfFormatInfo.pageBreakBeforeMaterial ? 0 : pdfFormatInfo.marginBeforeMaterial, pdfFormatInfo.pageBreakBeforeMaterial, offerInfo.calculation.materialPositions.length > 0));
      docDefinition.content.push(this.getOfferPositionTable(offerInfo.calculation.materialPositions, offerInfo.calculation.totals.materialTotal, pdfFormatInfo.marginAfterMaterial));
      docDefinition.content.push(this.getTableTitle('Gesamtbetrag', pdfFormatInfo.marginBeforeTotal, pdfFormatInfo.totalAndQrBillOnSameSite || pdfFormatInfo.pageBreakBeforeTotal));
      docDefinition.content.push(this.getTotalCostTable(offerInfo, pdfFormatInfo.marginAfterTotal));
      if (offerInfo.settings.footer) docDefinition.content.push(this.standardText(offerInfo.settings.footer, 20));

      return {
        name: docName,
        pdf: pdfMake.createPdf(docDefinition)
      };
    } catch (error) {
      useToast().error(`PDF-Generierung fehlgeschlagen: ${Utils.getError(error)}`);
      console.error(error);
    }
  }

  private static standardText(text: any, marginTop: number = 0, marginBottom: number = 10) {
    return {
      text: text,
      fontSize: 9,
      margin: [0, marginTop, 0, marginBottom] // left, top, right, bottom
    }
  }

  private static getAddresses(company: Company, customer: Customer, marginTop: number, marginBetween: number) {
    return {
      columns: [
        {
          // Company Address
          width: '*',
          text: [
            company.name,
            company.address.street,
            `${company.address.zip} ${company.address.city}`,
            company.uid,
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        },
        {
          width: marginBetween,
          text: ''
        },
        {
          // Customer Address
          width: '*',
          text: [
            customer.companyName,
            `${customer.lastname ? customer.title + '\n' + customer.firstname + ' ' + customer.lastname : ''}`,
            customer.address.street,
            `${customer.address.zip} ${customer.address.city}`
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        }
      ]
    }
  }

  private static getTableTitle(title: string, marginTop: number, pageBreak: boolean, show: boolean = true): any {
    return !show ? {} : {
      text: title,
      fontSize: 11,
      margin: [0, marginTop, 0, 10],
      bold: true,
      ...(pageBreak && { pageBreak: 'before' })
    }
  }

  private static getOfferPositionTable(positions: OfferPosition[], total: number, marginBottom: number): any {
    if (positions.length == 0) return {};

    const header: any[] = [
      {text: 'Beschreibung', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: 'Anzahl', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: 'Einheitspreis', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: `Total ${TenantSettings.getCurrency()}`, bold: true, alignment: 'right', noWrap: true, margin: [this.COL_MARGIN,0,0,0]}
    ];

    const body: any[][] = []
    const groupSizes: number[] = [1];
    let currentGroupSize = 1;
    positions.forEach(position => {
      if (Utils.isSingleOfferPosition(position)) {
        const entry = position.entries[0]
        body.push([
          {text: position.description, margin: [0,0,this.COL_MARGIN,0]},
          {text: `${entry.quantity} ${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
          {text: `${this.currency(entry.unitPrice)}/${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
          {text: Utils.formatCurrency(entry.total ?? 0), alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
        ]);
        groupSizes.push(currentGroupSize + 1);
        currentGroupSize += 1;
      } else {
        body.push([position.description, {}, {}, {}]);  // Date, Position Text
        position.entries.forEach(entry => {
          body.push([
            {text: '- ' + entry.description, margin: [0,0,this.COL_MARGIN,0]},                                                         // Worker name
            {text: `${entry.quantity} ${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},                 // Worked hours
            {text: `${this.currency(entry.unitPrice)}/${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]}, // Price per Hour
            {text: Utils.formatCurrency(entry.total ?? 0), alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}]);                                    // Total
        });
        groupSizes.push(currentGroupSize + position.entries.length + 1);
        currentGroupSize += position.entries.length + 1;
      }
    });

    const totalRow: any[] = [{text: 'Total', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]}, {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: Utils.formatCurrency(total), bold: true, alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
    ];

    return positions.length == 0 ? {} : {
      fontSize: 9,
      table: {
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto'],
        body: [header, ...body, totalRow]
      },
      layout: {
        hLineWidth: function(i: number, node: any) {
          if (groupSizes.includes(i)) return 0.5;
          return 0;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getTotalCostTable(offerInfo: OfferInfo, marginBottom: number): any {
    const calculation = offerInfo.calculation
    const totals = calculation.totals;
    const hasWorkPositions = calculation.workPositions.length > 0;
    const hasMaterialPositions = calculation.materialPositions.length > 0;
    const hasDiscounts = calculation.discounts.length > 0;

    const tableBody: any = []
    if (hasWorkPositions && (hasDiscounts || hasMaterialPositions)) tableBody.push([`Total ${offerInfo.settings.workTableTitle}`, {text: this.currency(totals.workTotal), alignment: 'right'}])
    if (hasMaterialPositions && (hasDiscounts || hasWorkPositions)) tableBody.push([`Total ${offerInfo.settings.materialTableTitle.split(';;;')[0]}`, {text: this.currency(totals.materialTotal), alignment: 'right'}])
    if (hasDiscounts && hasMaterialPositions && hasWorkPositions) tableBody.push(['Zwischentotal', {text: this.currency(totals.workTotal + totals.materialTotal), alignment: 'right'}]);

    calculation.discounts.forEach(item => {
      tableBody.push([
        `${Type.getDiscountType(item.discountTypeId).name}${item.relative ? '   ' + (item.deduction ? '-' : '+') + ' ' + item.amount + ' %' : ''}`,
        {text: `${item.deduction ? '-' : '+'} ${this.currency(item.total)}`, alignment: 'right'}
      ]);
    })

    if (!offerInfo.settings.pricesIncludingVat) tableBody.push([{ text: 'Total exklusive Mehrwertsteuer', bold:true }, {text: this.currency(totals.totalBeforeVat), bold: true, alignment: 'right'}]);
    if (!offerInfo.settings.pricesIncludingVat) tableBody.push([`Mehrwertsteuer (${offerInfo.company.vatRate} %)`, {text: this.currency(totals.totalVat), alignment: 'right'}])
    tableBody.push([{ text: 'Total inklusive Mehrwertsteuer', bold:true }, {text: this.currency(totals.totalAfterVat), bold: true, alignment: 'right'}]);

    return {
      fontSize: 9,
      table: {
        headerRows: 0,
        widths: ['*', '*'],
        body: tableBody,
      },
      layout: {
        hLineWidth: function(i: number, node: any) {
          if (i === 0 || i === node.table.body.length) return 0;
          if (i === node.table.body.length - 1) return 1.5;
          return 0.5;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getLogo(base64Logo: string, height: number, marginTop: number) {
    return {
      image: `data:image/png;base64,${base64Logo}`,
      fit: [500, height],
      margin: [0, marginTop, 0, 0]
    }
  }

  private static currency(value: number): string {
    return `${Utils.formatCurrency(value)} ${TenantSettings.getCurrency()}`
  }

  private static async convertToBase64(url: string) {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  private static lineBreak(value: string | undefined): string {
    if (!value || value == '') return '';
    return value + '\n';
  }


  // Custom Fields
  private static getFieldWidthForCustomFields(entity: CustomFieldEntity) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    return [...TenantFields.forEntity(entity).map(field => 'auto')];
  }

  private static getTableHeaderForCustomFields(entity: CustomFieldEntity) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    return [...TenantFields.forEntity(entity).map(field => { return {text: field.name, bold: true, margin: [1,0,1,0]}})];
  }

  private static getTableColumnsForCustomFields(entity: CustomFieldEntity, fieldValues: CustomFieldValue[] | undefined) {
    const columns: any[] = []
    for (const field of TenantFields.forEntity(entity)){
      const value = (fieldValues ?? []).find(v => v.customFieldId == field.id);
      const text = value ? (TenantFields.get(value.customFieldId)?.type == CustomFieldType.DATE ? DateConverter.convertToLocalDate(value.value) : value.value) : undefined
      columns.push(text ? {text: text, margin: [1,0,1,0]} : {text: '', margin: [1,0,1,0]});
    }
    return columns;
  }

  private static getEmptyObjectsForCustomFields(entity: CustomFieldEntity) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    return [...TenantFields.forEntity(entity).map(field => '')];
  }



}