<template>

  <div class="flex flex-row items-start">
    <FormKit v-if="type == ReportType.WORK" type="select" label="Arbeitervorlage" :options="presetOptions" v-model="selectedPresetId" />
    <FormKit v-else type="select" label="Einheit*" :options="Utils.toSelectOptions(Type.getAllUnits(), 'Einheit wählen')" v-model="unitId" />
    <base-button v-if="!single" look="secondary" type="icon" class="ml-auto h-fit rounded-lg" @click="openModal">
      <phosphor-icon icon="folder-open" />
      <span class="hidden md:block ml-5">{{ type == ReportType.WORK ? 'Pauschale Arbeit wählen' : 'Material wählen' }}</span>
    </base-button>
  </div>

  <FormKit v-if="type == ReportType.WORK && !selectedPresetId" type="select" label="Einheit*" :options="Utils.toSelectOptions(Type.getAllUnits(), 'Einheit wählen')" v-model="unitId" />
  <FormKit v-if="!single && (type == ReportType.MATERIAL || !selectedPresetId)" type="text" label="Name / Beschreibung*" validation="required" v-model="description" />
  <FormKit v-if="type == ReportType.MATERIAL || !selectedPresetId" type="number" inputmode="decimal" number step="any" :label="`Preis pro ${unitId ? Type.getUnit(unitId).abbreviation() : 'Einheit'}*`" validation="required" v-model="unitPrice" />

  <div v-if="selectByTime" class="flex flex-row items-center">
    <div>
      <div v-for="(times, index) of selectedTimes" :key="index" class="flex flex-row">
        <div class="mr-betweenElements">
          <FormKit type="select" :name="'fromTime' + index" :id="'fromTime' + index" :label="index == 0 ? 'Von': ''" :options="allTimeOptions" :value="times.from" class="flex-1 mr-6" @input="updateTime(index, $event, 'from')"/>
        </div>
        <FormKit type="select" :name="'toTime' + index" :id="'toTime' + index" :label="index == 0 ? 'Bis': ''" :options="allTimeOptions" :value="times.to" class="flex-1" @input="updateTime(index, $event, 'to')"/>
      </div>
      <div class="flex flex-row">
        <div class="mr-betweenElements">
          <FormKit type="select" name="fromTime" id="fromTime" :label="selectedTimes.length == 0 ? 'Von' : ''" :options="adaptedTimeOptions" v-model="fromTimeSelected" @change="onNewTimeSelected()"/>
        </div>
        <FormKit type="select" name="toTime" id="toTime" :label="selectedTimes.length == 0 ? 'Bis' : ''" :options="adaptedTimeOptions" v-model="toTimeSelected" @change="onNewTimeSelected()"/>
      </div>
    </div>

    <button class="ml-auto hover:text-primary transition-all h-fit" @click="selectByTime = false">
      <phosphor-icon icon="text-t" />
    </button>
  </div>

  <div v-if="!selectByTime" class="flex flex-row items-center">
    <div class="w-full">
      <FormKit id="calcInput"
               type="text"
               inputmode="decimal"
               :label="`Anzahl ${unitId ? Type.getUnit(unitId).abbreviation() : 'Einheit'}*`"
               validation="required"
               v-model="quantityInput"
               :help="quantityHelp"
               @focus="showOperations = true"
               @blur="handleBlur"
               class="flex-1"/>
      <div v-show="showOperations && !isFirefox" ref="operationBar" class="md:hidden flex justify-around">
        <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
        <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
        <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
        <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
      </div>

      <!-- Fixed bar at the bottom for Firefox since Firefox is the only mobile Browser that does not overlay the virtual
           keyboard over the web content but actually resizes the page. As long as a no solution like maybe VirtualKeyboard API
           is available for all browsers this is a Firefox feature only-->
      <div v-show="showOperations && isFirefox" class="md:hidden fixed inset-x-0 bottom-0 p-4 bg-white flex justify-around border-t border-grey-mid">
        <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
        <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
        <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
        <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
      </div>
    </div>

    <button v-if="type == ReportType.WORK" class="ml-betweenElements hover:text-primary transition-all h-fit" @click="selectByTime = true">
      <phosphor-icon icon="clock" />
    </button>
  </div>

  <categories-modal ref="categoryModal" :type="type" @receive-modal-category="receiveCategory"></categories-modal>

</template>

<script setup lang="ts">

import { ReportType, Type } from '@/model/Type'
import { Utils } from '@/client/utils'
import BaseButton from '@/components/base/BaseButton.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import type WorkerPreset from '@/model/WorkerPreset'
import { computed, defineExpose, nextTick, onMounted, ref, watch, watchEffect } from 'vue'
import { useToast } from 'vue-toast-notification'
import type Category from '@/model/Category'
import type { OfferPositionEntry } from '@/model/OfferPositionEntry'
import { ExpressionCalculator } from '@/service/ExpressionCalculator'


const props = defineProps<{
  existing: ReportPositionEntry | OfferPositionEntry,
  presets: WorkerPreset[],
  presetOptions: {value: string, label: string}[],
  type: ReportType,
  single: boolean,
}>();

const selectedPresetId = ref('');
const unitId = ref('');
const description = ref('');
const quantity = ref(0.0);
const unitPrice = ref(0.0);

const expense = ref(true);

const adaptedTimeOptions = ref(getTimeValues(16))
const allTimeOptions = ref(getTimeValues(16))
const fromTimeSelected = ref<number>(0);
const toTimeSelected = ref<number>(0);
const selectedTimes = ref<{from: number, to: number}[]>([]);

const quantityInput = ref('');
const quantityHelp = ref('');
const quantityInputValid = ref(false);
const showOperations = ref(false);
let operationsButtonClicked = false;
const userAgent = navigator.userAgent;
const isFirefox = computed(() => {
  return userAgent.includes('Firefox');
});
const selectByTime = ref(false);

const $toast = useToast();

const emits = defineEmits(['on-form-update', 'on-category-selected']);

watchEffect(() => {
  emitUpdate();
});

onMounted(() => {

})

watch(() => selectedPresetId.value, newVal => {
  if (!newVal) return;
  const preset = props.presets.find(preset => preset.id == newVal);
  if (!preset) return;

  description.value = preset.name;
  unitId.value = preset.unitId;
  unitPrice.value = preset.pricePerHour;
}, {
  immediate: true
});

watch(() => props.existing, newVal => {
  if (!newVal) return;
  if (newVal.unitId != '' && Type.getAllUnitsUnfiltered()[0].id != newVal.unitId) switchExpense(false);
  description.value = newVal.description ?? '';
  quantity.value = newVal.quantity;
  quantityInput.value = `${newVal.quantity}`;
  unitId.value = newVal.unitId
  unitPrice.value = newVal.unitPrice;
}, {
  immediate: true
});

watch(() => props.presets, newVal => {
  if (!newVal || newVal.length == 0) return;
  if (props.existing.unitId != '') return;
  const preselected = newVal.find(preset => preset.defaultSelected);
  if (!preselected) return;
  selectedPresetId.value = preselected.id;
}, {
  immediate: true
});

function switchExpense(value: boolean) {
  if (expense.value == value) return;
  description.value = '';
  quantity.value = 0;
  unitId.value = ''
  unitPrice.value = 0;
  expense.value = value;
}

const categoryModal = ref(CategoriesModal);
const openModal = () => {
  if (categoryModal.value) {
    categoryModal.value.openModal();
  }
};

function receiveCategory(category: Category) {
  switchExpense(false);
  selectedPresetId.value = '';
  description.value = category.invoiceInscription;
  unitId.value = category.standardUnitId ?? '';
  unitPrice.value = category.standardPrice ?? 0;
  emits('on-category-selected', category);
}

function emitUpdate() {
  // emits a ReportPositionEntry
  emits('on-form-update', {
    id: props.existing.id,
    description: props.single ? undefined : description.value,
    quantity: quantity.value,
    unitId: unitId.value,
    unitPrice: unitPrice.value
  });
}

defineExpose({
  receiveCategory
})



// Regex calculation
watch(() => quantityInput.value, newVal => {
  const result = ExpressionCalculator.calculate(newVal);
  quantityInputValid.value = !!result;
  quantity.value = result ?? 0;
  quantityHelp.value = result != null ? `= ${quantity.value}` : 'Eingabe Fehlerhaft';
}, {
  immediate: true
});

function addToInput(symbol: string) {
  operationsButtonClicked = true;
  quantityInput.value += quantityInput.value == '' ? symbol : ` ${symbol} `;
  nextTick(() => {
    const inputElement = document.getElementById('calcInput');
    if (inputElement) {
      inputElement.focus();  // Refocus on the input element
    }
  });
}

function handleBlur() {
  setTimeout(() => {
    if (operationsButtonClicked) {
      operationsButtonClicked = false;
      return;
    }
    showOperations.value = false;
  }, 100);  // Delay the hiding to allow button click to execute addToInput
}



//Time calculation
function getTimeValues(start: number): {value: number, label: string}[] {
  const result: {value: number, label: string}[] = [];
  result.push({value: 0, label: 'Zeit wählen'})
  for (let i = start; i <= 24*4; i++) {
    result.push({value: i, label: `${i / 4 < 10 ? '0' : ''}${Math.floor(i / 4)}:${getMinutes(i)}`})
  }
  return result;
}

function getMinutes(i: number) {
  switch (i % 4) {
    case 0: return '00';
    case 1: return '15';
    case 2: return '30';
    case 3: return '45';
  }
}

function onNewTimeSelected() {
  if (fromTimeSelected.value == 0) return;
  if (toTimeSelected.value == 0) {
    adaptedTimeOptions.value = getTimeValues(fromTimeSelected.value);
    return;
  }
  if (toTimeSelected.value - fromTimeSelected.value < 0) {
    $toast.error('Von-Zeit kleiner als Bis-Zeit');
    console.error('from time larger than to time');
    return;
  }

  selectedTimes.value.push({from: fromTimeSelected.value, to: toTimeSelected.value});
  fromTimeSelected.value = 0;
  toTimeSelected.value = 0;
  calculateTime();
}

function updateTime(index: number, value: number | undefined, field: string) {
  if (!value) return;
  if (field === 'from') {
    selectedTimes.value[index].from = value;
  } else {
    selectedTimes.value[index].to = value;
  }

  selectedTimes.value = [...selectedTimes.value];
  calculateTime();
}

function calculateTime() {
  let totalTime = 0;
  selectedTimes.value.forEach(times => {
    totalTime += (times.to - times.from) * 0.25;
  })
  quantity.value = totalTime;
  adaptedTimeOptions.value = getTimeValues(selectedTimes.value[selectedTimes.value.length - 1].to);
}


</script>

<style scoped>

</style>