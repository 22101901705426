<template>

  <FormKit v-for="(field, index) in TenantFields.forEntity(entity)" :key="field.id"
           :id="field.name"
           :type="field.type.toLowerCase() as any"
           :label="field.name"
           :inputmode="field.type == CustomFieldType.NUMBER ? 'decimal' : 'text'"
            v-model="values[index].value"/>

</template>

<script setup lang="ts">

import { type CustomFieldEntity, CustomFieldType, type CustomFieldValue } from '@/model/CustomField'
import { TenantFields } from '@/stores/TenantFields'
import { ref, watch } from 'vue'

const props = defineProps<{
  entity: CustomFieldEntity,
  entityId: string,
  customFieldValues?: CustomFieldValue[]
}>();

const values = ref<CustomFieldValue[]>(getCustomFieldValueArray(props.entity));

watch(() => props.customFieldValues, newVal => {
  if (!newVal || newVal.length == 0) return;
  for (const value of values.value) {
    const newV = newVal.find(v => v.customFieldId == value.customFieldId);
    if (newV) {
      value.id = newV.id;
      value.entityId = newV.entityId;
      value.value = newV.value;
    }
  }
}, {
  immediate: true
});

const getValues = (): CustomFieldValue[] | undefined => {
  if (TenantFields.forEntity(props.entity).length == 0) return;

  const convertedValues: CustomFieldValue[] = [];

  for (const field of values.value) {
    if (!field.value || field.value == '') continue;
    convertedValues.push({
      id: field.id ?? '',
      customFieldId: field.customFieldId,
      entityId: props.entityId,
      value: field.value,
    });
  }
  return convertedValues;
};

function getCustomFieldValueArray(entity: CustomFieldEntity) {
  const array: CustomFieldValue[] = [];
  for (const field of TenantFields.forEntity(entity)) array.push(getEmptyCustomFieldValue(field.id));
  return array;
}

function getEmptyCustomFieldValue(customFieldId: string): CustomFieldValue {
  return {
    id: '',
    customFieldId: customFieldId,
    entityId: '',
    value: ''
  }
}

defineExpose({
  getValues
});

</script>

<style scoped>

</style>