<template>
  <div v-if="isVisible" class="modal-container">
    <div class="modal-small-content w-modalWidth">

      <!-- Title-->
      <div class="p-8 mb-11 text-title">{{title}}</div>

      <!-- Description -->
      <p class="p-8 text-bodyMediumBold"><slot></slot></p>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0">
        <div class="flex">
          <base-button class="flex-1 m-8" look="secondary" @click="closeModal">
            {{cancelText}}
          </base-button>

          <base-button class="flex-1 m-8" @click="onConfirm">
            {{confirmText}}
          </base-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'

const props = withDefaults(defineProps<{
  title: string;
  confirmText: string
  cancelText?: string
}>(), {
  cancelText: 'Abbrechen'
});

const isVisible = ref(false);
const emits = defineEmits(['on-confirm', 'close-action']);

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  emits('close-action');
  isVisible.value = false;
};

function onConfirm() {
  emits('on-confirm');
  closeModal();
}

defineExpose({
  openModal,
  closeModal
});

</script>

<style scoped>

</style>