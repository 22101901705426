<template>
  <div>
    <template v-if="single && allFormInput.length > 0">
      <entry-form ref="singleEntryForm"
                  :existing="allFormInput[0]"
                  :presets="presets"
                  :preset-options="presetOptions"
                  :type="type"
                  :single="true"
                  @on-form-update="onSingleFormUpdate"/>
    </template>

    <template v-else>
      <worker-form-card v-for="(form, index) of allFormInput" :key="index"
                        :index="index"
                        :existing="form"
                        :presets="presets"
                        :preset-options="presetOptions"
                        :extended="extendedForm == index"
                        :delete-enabled="allFormInput.length > 1"
                        :type="type"
                        @update-worker="updateForm"
                        @on-extend="onExtendForm"
                        @on-delete="onDeleteForm">
      </worker-form-card>

      <div class="flex md:flex-row flex-col">
        <base-button class="mt-betweenElements md:mr-auto md:mb-0 mb-8" type="icon" look="secondary" @click="onAddForm()">
          <base-icon class="mr-5">add</base-icon>
          <span>Weitere Position hinzufügen</span>
        </base-button>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { defineExpose, onMounted, ref, watch } from 'vue'
import { API, PathSegment } from '@/client/axios'
import type WorkerPreset from '@/model/WorkerPreset'
import { useToast } from 'vue-toast-notification'
import { ReportType, Type } from '@/model/Type'
import WorkerFormCard from '@/components/workReportPosition/WorkerFormCard.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import type Category from '@/model/Category'
import EntryForm from '@/components/workReportPosition/EntryForm.vue'
import type { OfferPositionEntry } from '@/model/OfferPositionEntry'
import { TenantSettings } from '@/stores/TenantSettings'

const props = defineProps<{
  existingEntries: ReportPositionEntry[] | OfferPositionEntry[];
  type: ReportType;
  single: boolean;
}>();

const presets = ref<WorkerPreset[]>([]);
const presetOptions = ref<{value: string, label: string}[]>([{value: '', label: "Keine"}]);

const allFormInput = ref<ReportPositionEntry[]>([]);
const allFormsOutput = ref<ReportPositionEntry[]>([]);
const extendedForm = ref(0);
const emits = defineEmits(['receive-entries']);

const singleEntryForm = ref(EntryForm);

const $toast = useToast();

watch(() => props.existingEntries, newVal => {
  if (!newVal || newVal.length == 0) return;
  const forms: ReportPositionEntry[] = []
  newVal.forEach(entry => forms.push(entry));
  allFormInput.value = forms;
  extendedForm.value = -1;
}, {
  immediate: true
});

function updateForm(index: number, entry: ReportPositionEntry) {
  allFormsOutput.value[index] = entry;
}

function onExtendForm(index: number) {
  if (extendedForm.value == index) extendedForm.value = -1;
  else extendedForm.value = index;
}

function onAddForm() {
  allFormsOutput.value.push(getStandardForm());
  allFormInput.value.push(getStandardForm());
  extendedForm.value = allFormInput.value.length - 1;
}

function onDeleteForm(index: number) {
  if (index < 0 || index >= allFormInput.value.length) {
    $toast.error('Arbeiter nicht gefunden (index out of range)');
    return;
  }
  if (allFormInput.value.length == 1) {
    $toast.error('Löschen nicht möglich. Mindestens ein Arbeiter muss vorhanden sein');
    return;
  }

  allFormsOutput.value.splice(index, 1);
  allFormInput.value = [...allFormsOutput.value];
}

function emitForms() {
  emits('receive-entries',
    props.single
      ? [allFormsOutput.value[0]]
      : allFormsOutput.value);
}

function receiveCategory(category: Category) {
  if (!singleEntryForm.value) return;
  singleEntryForm.value.receiveCategory(category);
}

function onSingleFormUpdate(entry: ReportPositionEntry) {
  allFormsOutput.value[0] = entry;
}

async function loadPresets() {
  const response = await API.getDataObject<WorkerPreset[]>(PathSegment.WORK_REPORT_POSITION_PRESET, '', 'Arbeitervorlagen');
  if (!response) return;
  presetOptions.value = [presetOptions.value[0], ...response.map(p => {
    return { value: p.id, label: `${p.name} (${p.pricePerHour} ${TenantSettings.getCurrency()}/${Type.getUnit(p.unitId).abbreviation()})` };
  })];
  presets.value = response;
  const preSelected = presets.value.find(p => p.defaultSelected);
  if (allFormInput.value.length == 0) allFormInput.value.push(getStandardForm());
}

onMounted(async () => {
  if (props.type == ReportType.WORK) await loadPresets();
  else if (allFormInput.value.length == 0) allFormInput.value.push(getStandardForm());
  if (!props.existingEntries) extendedForm.value = 0;
});

function getStandardForm(): ReportPositionEntry {
  return {
    id: '',
    description: '',
    quantity: 0,
    unitPrice: 0,
    unitId: '',
    total: 0
  }
}

defineExpose({
  emitForms,
  receiveCategory
});

</script>

<style scoped>

</style>
