<template>

  <base-site :title="titleText" :show-button="!!customerId" button-icon="trash" button-look="secondary-light" @button-action="openModal">

    <floating-spinner v-if="customerId && loading" />

    <base-container v-else class="mb-betweenElements">
      <radio-select ref="customerTypeRadioSelect"
                    :options="options"
                    :init-option="OPTION_UNDEFINED"
                    orientation="horizontal"
                    @on-select="value => customerTypeOption = value" />
      <template v-if="customerTypeOption != OPTION_UNDEFINED">
        <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Anschrift</base-heading>
        <FormKit v-if="customerTypeOption == OPTION_COMPANY" type="text" input="disabled" label="Firmenname*" v-model="customerCompanyName" />
        <FormKit type="select" :label="`Titel${customerTypeOption == OPTION_PRIVATE_PERSON ? '*' : ''}`" :options="titleOptions" v-model="customerTitle" />
        <FormKit type="text" label="Vorname" v-model="customerFirstname" />
        <FormKit type="text" :label="`Nachname${customerTypeOption == OPTION_PRIVATE_PERSON ? '*' : ''}`" v-model="customerLastname" />

          <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Kundenadresse</base-heading>
          <FormKit type="group" name="address" id="address">
            <FormKit type="text" name="street" id="street" label="Strasse*" validation="required" v-model="customerAddressStreet" />
            <div class="flex flex-col md:flex-row">
              <div class="md:flex-3 md:mr-betweenElements"><FormKit type="text" name="zip" id="zip" label="PLZ*" validation="required" v-model="customerAddressZip" /></div>
              <div class="md:flex-1"><FormKit type="text" name="city" id="city" label="Ort*" validation="required" v-model="customerAddressCity" /></div>
            </div>
            <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
              <FormKit type="text" name="state" id="state" label="Kanton" v-model="customerAddressState" :classes="{outer: 'flex-1 mb-0'}" />
              <FormKit type="text" name="country" id="country" label="Land" v-model="customerAddressCountry" :classes="{outer: 'flex-1 mb-0'}" />
            </div>
          </FormKit>
      </template>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Kunde löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll der Kunde wirklich gelöscht werden?</confirmation-modal>
    </base-container>

    <div v-if="customerTypeOption != OPTION_UNDEFINED" class="flex md:flex-row flex-col">
      <base-button look="primary" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button look="secondary-light" class="md:mx-0 mx-betweenElements mt-8 md:mt-0 md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>
  </base-site>

</template>

<script setup lang="ts">

import { API, PathSegment } from '@/client/axios'
import { useRoute } from 'vue-router'
import router, { routeNames } from '@/router'
import { nextTick, onMounted, ref } from 'vue'
import type Customer from '@/model/Customer'
import BaseSite from '@/components/base/BaseSite.vue'
import { useToast } from 'vue-toast-notification'
import { TenantCompany } from '@/stores/TenantCompany'
import BaseContainer from '@/components/base/BaseContainer.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import type Project from '@/model/Project'
import { ResponseKey } from '@/model/ResponseWrapper'
import FloatingSpinner from '@/components/generel/FloatingSpinner.vue'
import RadioSelect from '@/components/generel/RadioSelect.vue'

const CUSTOMER = 'Kunde';
const OPTION_UNDEFINED = 0;
const OPTION_COMPANY = 1;
const OPTION_PRIVATE_PERSON = 2;
const options = [{label: 'Firma', value: OPTION_COMPANY}, {label: 'Privatperson', value: OPTION_PRIVATE_PERSON}];
const route = useRoute();

const loading = ref(false);
const customerTypeOption = ref(OPTION_UNDEFINED);
const requesting = ref(false);
const customerId = route.params.id as string;
const customer = ref<Customer>();
const titleText = ref('Projekt');
const submitText = ref<string>();

const titleOptions = ref<{value: string, label: string}[]>([{value: '', label: 'Titel wählen'}, {value: 'Herr', label: 'Herr'}, {value: 'Frau', label: 'Frau'}, {value: 'Familie', label: 'Familie'}, {value: 'Dr.', label: 'Dr.'}]);
const customerTitle = ref<string>('');
const customerFirstname = ref<string>('');
const customerLastname = ref<string>('');
const customerCompanyName = ref<string>('');

const customerAddressStreet = ref<string>();
const customerAddressCity = ref<string>();
const customerAddressZip = ref<string>();
const customerAddressState = ref<string>();
const customerAddressCountry = ref<string>();

const customerTypeRadioSelect = ref(RadioSelect);
const confirmDeletionModal = ref(ConfirmationModal);
const $toast = useToast()

async function onSubmit() {
  const fields: any = {
    title: customerTitle.value,
    firstname: customerFirstname.value,
    lastname: customerLastname.value,
    companyName: customerCompanyName.value,
    address: {
      street: customerAddressStreet.value,
      city: customerAddressCity.value,
      zip: customerAddressZip.value,
      state: customerAddressState.value,
      country: customerAddressCountry.value
    }
  }

  if (customerId) await onUpdate(fields);
  else await onCreate(fields);
}

async function onCreate(fields: any) {
  if (requesting.value || !checkPersonOrCompany(fields)) return;

  requesting.value = true;
  fields = cleanFields(fields);
  fields.companyId = TenantCompany.getCompany().id;
  const response = await API.createDataObject<Customer>(PathSegment.CUSTOMERS, fields, CUSTOMER);
  if (response) await navigateToCustomer(response.id);
  requesting.value = false;
}

async function onUpdate(fields: any) {
  if (requesting.value) return;
  if (!customer.value) {
    $toast.error('Kunde nicht gefunden');
    return;
  }
  if (!checkPersonOrCompany(fields)) return;

  requesting.value = true;
  fields = cleanFields(fields);
  fields.id = customerId;
  fields.companyId = TenantCompany.getCompany().id;
  fields.address.id = customer.value?.address.id;
  const response = await API.updateDataObject<Customer>(PathSegment.CUSTOMERS, fields, CUSTOMER);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.CUSTOMERS, customerId, CUSTOMER);
  if (success) await navigateToAllCustomers();
}

async function navigateToCustomer(id: string) {
  if (TenantSettings.isProjectOriented()) {
    await router.push({name: routeNames.CUSTOMER, params: {id: id}});
    return;
  }
  const response = await API.get<Project[]>(PathSegment.CUSTOMERS, id, PathSegment.PROJECTS);
  if (response && response.key == ResponseKey.OK && response.data.length > 0) {
    await router.push({name: routeNames.PROJECT, params: {id: response.data[0].id}});
  } else {
    $toast.info("Kunde erstellt aber konnte nicht geöffnet werden")
  }
}

async function navigateToAllCustomers() {
  if (TenantSettings.isProjectOriented()) await router.push({name: routeNames.CUSTOMERS});
  await router.push({name: routeNames.PROJECTS});
}

function onCancel() {
  router.go(-1);
}

function checkPersonOrCompany(fields: any): boolean {
  if (customerTypeOption.value != OPTION_PRIVATE_PERSON && customerTypeOption.value != OPTION_COMPANY) {
    $toast.info('Person oder Firma muss ausgefüllt sein');
    return false;
  }

  if (customerTypeOption.value == OPTION_PRIVATE_PERSON && (undefinedOrEmpty(fields.title) || undefinedOrEmpty(fields.lastname))) {
    $toast.info('Person unvollständig ausgefüllt');
    return false;
  }

  if (customerTypeOption.value == OPTION_COMPANY && undefinedOrEmpty(fields.companyName)) {
    $toast.info('Firma unvollständig ausgefüllt');
    return false;
  }

  if (undefinedOrEmpty(fields.address.street) || undefinedOrEmpty(fields.address.city) || undefinedOrEmpty(fields.address.zip)) {
    $toast.info('Adresse unvollständig ausgefüllt');
    return false;
  }

  return true;
}

function undefinedOrEmpty(field: string) {
  return !field || field == '';
}

function cleanFields(fields: any): any {
  if (customerTypeOption.value != OPTION_COMPANY) {
    fields.companyName = '';
  }
  return fields;
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

async function loadCustomer() {
  loading.value = true;
  const response = await API.getDataObject<Customer>(PathSegment.CUSTOMERS, customerId);
  if (!response) return;
  customer.value = response;
  customerTitle.value = response.title;
  customerFirstname.value = response.firstname;
  customerLastname.value = response.lastname;
  customerCompanyName.value = response.companyName;
  customerAddressStreet.value = response.address.street;
  customerAddressCity.value = response.address.city;
  customerAddressZip.value = response.address.zip;
  customerAddressState.value = response.address.state;
  customerAddressCountry.value = response.address.country;
  loading.value = false;
  await nextTick();
  setCustomerType(response.companyName ? OPTION_COMPANY : OPTION_PRIVATE_PERSON);
}

onMounted(async () => {
  if (customerId) {
    titleText.value = 'Kunde bearbeiten'
    submitText.value = 'Kunde speichern';
    await loadCustomer();
  } else {
    titleText.value = 'Kunde erstellen';
    submitText.value = 'Kunde erfassen';
  }
});

function setCustomerType(value: number) {
  customerTypeOption.value = value;
  if (!customerTypeRadioSelect.value) return;
  customerTypeRadioSelect.value.setSelected(value);
}

</script>

<style scoped>

</style>