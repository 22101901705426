<template>
  <div class="flex flex-col space-y-betweenElements sm:mt-outerFrameBorder mt-betweenElements">

    <div class="flex flex-col lg:flex-row space-y-betweenElements lg:space-y-0 lg:space-x-betweenElements !mt-0">
      <DashboardQuickActions id="quickActions" class="offset-scroll-into-view" />
      <DashboardProjectOverview id="open" class="flex-1 offset-scroll-into-view"
                                :projects="latestProjectsOpen"
                                :loading="latestProjectsOpenLoading">
        <div class="title-icon-with-background">
          <phosphor-icon icon="hammer" />
        </div>
        {{ TenantSettings.isProjectOriented() ? 'Offene Projekte' : 'Laufende Rechnungen' }}
      </DashboardProjectOverview>
    </div>

    <DashboardProjectOverview id="lastUpdated" class="flex-1 offset-scroll-into-view"
                              :projects="latestProjectsAnyStatus"
                              :loading="latestProjectsAnyStatusLoading">
      <div class="title-icon-with-background">
        <phosphor-icon icon="clock-counter-clockwise" />
      </div>
      Zuletzt bearbeitete {{ TenantSettings.isProjectOriented() ? 'Projekte' : 'Kunden' }}
    </DashboardProjectOverview>

    <DashboardProjectOverview id="finished" class="flex-1 offset-scroll-into-view"
                              :projects="latestProjectsClosed"
                              :loading="latestProjectsClosedLoading">
      <div class="title-icon-with-background">
        <phosphor-icon icon="checks" />
      </div>
      Zuletzt abgeschlossene {{ TenantSettings.isProjectOriented() ? 'Projekte' : 'Rechnungen' }}
    </DashboardProjectOverview>

    <base-container class="sm:hidden !bg-grey-mid border-none sticky bottom-0 flex items-center justify-between h-overviewNav !py-7">
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('quickActions')">
        <phosphor-icon icon="lightning" class="text-[calc(theme(fontSize.iconSize)+0.25rem)]"/>
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('open')">
        <phosphor-icon icon="hammer" />
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('lastUpdated')">
        <phosphor-icon icon="clock-counter-clockwise" />
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('finished')">
        <phosphor-icon icon="checks" />
      </base-button>
    </base-container>

  </div>
</template>

<script setup lang="ts">
import DashboardQuickActions from '../../components/dashboard/DashboardQuickActions.vue'
import DashboardProjectOverview from '../../components/dashboard/DashboardProjectOverview.vue'
import { API, PathSegment } from '@/client/axios'
import { onMounted, ref } from 'vue'
import { ResponseKey } from '@/model/ResponseWrapper'
import type Project from '@/model/Project'
import { Utils } from '@/client/utils'
import { useToast } from 'vue-toast-notification'
import { Type } from '@/model/Type'
import { TenantSettings } from '../../stores/TenantSettings'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const latestProjectsAnyStatus = ref<Project[]>([]);
const latestProjectsAnyStatusLoading = ref(true);
const latestProjectsOpen = ref<Project[]>([]);
const latestProjectsOpenLoading = ref(true);
const latestProjectsClosed = ref<Project[]>([]);
const latestProjectsClosedLoading = ref(true);

const $toast = useToast();

const loadProjectsAnyStatus = async () => {
  latestProjectsAnyStatus.value = await loadProjects(new Map<string,string>([['includeCustomer', 'true'], ['limit', '8']]), 'Zuletzt bearbeitete Projekte') ?? [];
  latestProjectsAnyStatusLoading.value = false;
};

const loadProjectsOpen = async () => {
  latestProjectsOpen.value = await loadProjects(new Map<string,string>([['includeCustomer', 'true'], ['limit', '8'], ['statusId', getStatusStartsWith('ongoing')]]), 'Offene Projekte') ?? [];
  latestProjectsOpenLoading.value = false;
};

const loadProjectsClosed = async () => {
  latestProjectsClosed.value = await loadProjects(new Map<string,string>([['includeCustomer', 'true'], ['limit', '8'], ['statusId', getStatusStartsWith('ended')]]), 'Abgeschlossene Projekte') ?? [];
  latestProjectsClosedLoading.value = false;
};

async function loadProjects(parameter: Map<string, string>, projectType: string = 'Projekte') {
  try {
    const response = await API.getWithParameters<Project[]>(PathSegment.PROJECTS, parameter);
    if (response.key == ResponseKey.OK) {
      return response.data;
    } else {
      $toast.error(`${projectType} konnten nicht geladen werden: ${response.message}`);
      console.error(`${projectType} could not be loaded: ${response.message}`);
    }
  } catch (error) {
    $toast.error(`${projectType} konnten nicht geladen werden: ${Utils.getError(error)}`);
    console.error(`${projectType} could not be loaded: ${Utils.getError(error)}`);
  }
}

function getStatusStartsWith(value: string) {
   const status = Type.getAllStatuses().find(s => s.id.startsWith(value));
   if (!status) {
     console.error(`No status starts with ${value}`);
     return '';
   }
   return status.id;
}

onMounted(() => {
  loadProjectsAnyStatus();
  loadProjectsOpen();
  loadProjectsClosed();
});

const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'center'
  })
}
</script>

<style scoped>
  .overview-nav-btn {
    @apply h-full self-stretch aspect-square p-0 bg-transaprent border-none hover:bg-transaprent hover:text-primary active:text-white active:bg-primary;
  }

  .offset-scroll-into-view {
    @apply scroll-my-[theme(spacing.headerHeight)];
  }
</style>
