import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'
import type { CustomField } from '@/model/CustomField'
import { CustomFieldEntity } from '@/model/CustomField'

type CustomFieldEmitter = (customFields: CustomField[]) => undefined;
export class TenantFields {

  private static _fields: CustomField[];
  private static customFieldsEmitters: CustomFieldEmitter[] = [];

  static get(id: string) {
    return this._fields.find(f => f.id == id);
  }

  static get fields() {
    if (!this._fields) this.loadFieldsFromStore();
    return this._fields;
  }

  static forEntity(entity: CustomFieldEntity) {
    return this._fields.filter(f => f.entity == entity);
  }

  static set fields(customFields: CustomField[]) {
    this.setAndEmitFields(customFields);
  }

  static loadFieldsFromStore() {
    const loaded = ObjectStorage.get<CustomField[]>(StorageKey.CUSTOM_FIELDS);
    if (!loaded) return false;
    this.setAndEmitFields(loaded);
    return true;
  }

  static register(customFieldsEmitter: CustomFieldEmitter) {
    this.customFieldsEmitters.push(customFieldsEmitter);
    if (this._fields) customFieldsEmitter(this._fields);
  }

  private static setAndEmitFields(customFields: CustomField[]) {
    this._fields = customFields;
    this.customFieldsEmitters.forEach(emit => emit(this._fields));
  }
}