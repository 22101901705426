export interface CustomField {
  id: string,
  entity: CustomFieldEntity,
  name: string,
  type: CustomFieldType,
  charge: boolean,
  relative: boolean,
  deduction: boolean,
  createdAt: number,
}

export interface CustomFieldRequest {
  entity: CustomFieldEntity,
  name: string,
  type: CustomFieldType,
  discountTypeId?: string,
}

export interface CustomFieldValue {
  id: string,
  customFieldId: string,
  entityId: string
  value: string,
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE'
}

export enum CustomFieldEntity {
  CUSTOMER = 'Customer',
  PROJECT = 'Project',
  SUBPROJECT = 'Subproject',
  WORK_REPORT_POSITION = 'WorkReportPosition',
  MATERIAL_REPORT_POSITION = 'MaterialReportPosition',
  OFFER = 'Offer',
  OFFER_POSITION = 'OfferPosition',
  DISCOUNT = 'Discount'
}