<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <phosphor-icon :icon="type == ReportType.WORK ? 'hammer' : 'package'" />
        </div>
        {{ type == ReportType.WORK ? 'Arbeitsrapport' : 'Materialrapport'}}
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddReportPosition()">
        <phosphor-icon icon="plus" />
      </base-button>
    </div>

    <spinner v-if="positionsLoading"></spinner>

    <div v-else>
      <div v-if="tableReportPositions && tableReportPositions.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>
      <div v-else>

          <!-- Desktop Table -->
          <div class="hidden md:block">
            <div v-if="tableReportPositions" class="grid grid-cols-[auto_auto_1fr_auto_auto_auto] auto-rows-[minmax(min-content,max-content)]">

              <!-- Header -->
              <div class="contents mx-2 my-5 md:my-2 items-center">

                <div class="pl-3 pr-0 sm:py-4 border border-r-0 rounded-l-md border-grey-mid bg-grey-mid mb-2 min-w-8"></div>
                <div class="pr-8 pl-0 sm:py-4 whitespace-nowrap overflow-hidden text-ellipsis border-t border-b border-grey-mid bg-grey-mid mb-2">
                  Datum
                </div>
                <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                  Beschreibung
                </div>
                <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                  Menge
                </div>
                <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                  Einheitspreis
                </div>
                <div class="pr-4 pl-0 sm:py-4 text-right border border-l-0 rounded-r-md border-grey-mid bg-grey-mid mb-2">
                  Total
                </div>

              </div>

              <!-- Content -->
              <div v-for="(cell, index) in tableReportPositions" :key="index" class="contents mx-2 my-2 items-center" :class="`${cell.visible || locked ? '' : 'hover:text-primary'} ${locked ? '' : 'row hover:cursor-pointer'}`">

                <div class="text-bodyMediumNormal pl-3 pr-0 pt-5 pb-3 border border-r-0 border-grey-mid mt-2" @click="Utils.isSingleReportPosition(cell.position) ? onEditReportPosition(cell.position.id) : toggleVisibility(index)"
                     :class="`${cell.visible ? 'mb-0 border-primary rounded-tl-md' : 'mb-2 border-grey-mid rounded-l-md'}`">
                  <button class="pt-3 pb-0">
                    <base-icon class="h-5" v-if="!Utils.isSingleReportPosition(cell.position)">
                      {{cell.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                    </base-icon>
                  </button>
                </div>

                <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                     @click="onEditReportPosition(cell.position.id)">
                  {{ cell.position.date ? DateConverter.convertToLocalDate(cell.position.date) : '-' }}
                </div>

                <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                     @click="onEditReportPosition(cell.position.id)">
                  {{ cell.position.description }}
                </div>

                <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                     @click="onEditReportPosition(cell.position.id)">
                  {{ getQuantity(cell.position) }}
                </div>

                <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                     @click="onEditReportPosition(cell.position.id)">
                  {{ getUnitPrice(cell.position) }}
                </div>

                <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right" :class="`${cell.visible ? 'mb-0 border-primary rounded-tr-md' : 'mb-2 border-grey-mid rounded-r-md'}`"
                     @click="onEditReportPosition(cell.position.id)">
                  {{ Utils.formatCurrency(calculateTotalCosts(cell.position.entries)) }}
                  {{ TenantSettings.getCurrency() }}
                </div>

                <!-- Sub-Table for Position Entries -->
                <div v-if="cell.visible" class="col-span-6 border-b border-l border-r rounded-b-md border-primary mb-2" @click="toggleVisibility(index)">
                  <!-- Header -->
                  <div class="grid grid-cols-[3fr_1fr_1fr_1fr] auto-rows-[minmax(min-content,max-content)]">
                    <div class="contents items-center bg-grey-light">
                      <div class="pl-5 pr-8 py-4 bg-grey-light">Beschreibung</div>
                      <div class="pr-8 py-4 bg-grey-light">Menge</div>
                      <div class="pr-8 py-4 bg-grey-light">Einheitspreis</div>
                      <div class="pr-5  py-4 text-right bg-grey-light">Total</div>
                    </div>
                    <!-- Content -->
                    <div v-for="entry of cell.position.entries" :key="entry.id" class="contents items-center">
                      <div class="pl-5 pr-8 py-4 border-t border-grey-mid">{{ entry.description }}</div>
                      <div class="pr-8 py-4 border-t border-grey-mid">{{ entry.quantity }} {{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                      <div class="pr-8 py-4 border-t border-grey-mid">{{ Utils.formatCurrency(entry.unitPrice) }} {{ TenantSettings.getCurrency() }}/{{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                      <div class="pr-5  py-4 border-t border-grey-mid text-right">{{ Utils.formatCurrency(entry.total ?? 0) }} {{ TenantSettings.getCurrency() }}</div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        <!-- Mobile Table -->
        <div class="block md:hidden">
          <div v-if="tableReportPositions" class="grid grid-cols-[1fr_auto] auto-rows-[minmax(min-content,max-content)]">
            <!-- Header -->
            <div class="contents mx-2 my-5 md:my-2 items-center">
              <div class="pl-3 pr-0 py-2 border border-r-0 rounded-l border-grey-mid bg-grey-mid mb-2">
                <div class="text-bodyMediumBold">Datum</div>
                <div>Beschreibung</div>
              </div>
              <div class="pr-4 pl-0 py-2 text-right border border-l-0 rounded-r border-grey-mid bg-grey-mid mb-2">
                <div class="text-bodyMediumBold">Menge</div>
                <div>Total</div>
              </div>
            </div>

            <div v-for="(cell, index) in tableReportPositions" :key="index" class="contents mx-2 my-2 hover:cursor-pointer items-center">
              <!-- Content -->
              <div class="pl-3 pr-4 pt-5 pb-3 border border-r-0 border-grey-mid mt-2"
                   :class="`${cell.visible ? 'mb-0 border-primary rounded-tl' : 'mb-2 border-grey-mid rounded-l'}`">

                <div class="flex flex-row" @click="Utils.isSingleReportPosition(cell.position) ? onEditReportPosition(cell.position.id) : toggleVisibility(index)">
                  <button class="py-0">
                    <base-icon class="h-5" v-if="!Utils.isSingleReportPosition(cell.position)">
                      {{cell.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                    </base-icon>
                  </button>
                  <div class="text-bodyMediumBold">
                    {{ cell.position.date ? DateConverter.convertToLocalDate(cell.position.date) : '-' }}
                  </div>
                </div>

                <div @click="onEditReportPosition(cell.position.id)">{{ cell.position.description }}</div>
              </div>

              <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right" :class="`${cell.visible ? 'mb-0 border-primary rounded-tr' : 'mb-2 border-grey-mid rounded-r'}`"
                   @click="onEditReportPosition(cell.position.id)">
                <div class="text-bodyMediumBold">{{ getQuantity(cell.position) }}</div>
                <div>{{ Utils.formatCurrency(calculateTotalCosts(cell.position.entries)) }}
                  {{ TenantSettings.getCurrency() }}</div>
              </div>

              <!-- Line that has the full table length and is underneath the cells above -->
              <div v-if="cell.visible" class="col-span-2 border-b border-l border-r rounded-b border-primary mb-2" @click="toggleVisibility(index)">
                <!-- Header -->
                <div class="grid grid-cols-[1fr_auto_auto] auto-rows-[minmax(min-content,max-content)]">
                  <div class="contents items-center bg-grey-light">
                    <div class="pl-5 pr-8 py-4 bg-grey-light">Beschreibung</div>
                    <div class="pr-8 py-4 bg-grey-light">Menge</div>
                    <div class="pr-5  py-4 text-right bg-grey-light">Total</div>
                  </div>
                  <div v-for="entry of cell.position.entries" :key="entry.id" class="contents items-center">
                    <!-- Content -->
                    <div class="pl-5 pr-8 py-4 border-t border-grey-mid">{{ entry.description }}</div>
                    <div class="pr-8 py-4 border-t border-grey-mid">{{ entry.quantity }} {{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                    <div class="pr-5  py-4 border-t border-grey-mid text-right">{{ Utils.formatCurrency(entry.total ?? 0) }} {{ TenantSettings.getCurrency() }}</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

            <div class="flex mt-2">
              <div v-if="totalPages > 1" class="flex flex-row ml-auto">
                <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
                <div class="cursor-default">{{offset + 1}} / {{totalPages}}</div>
                <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
              </div>
            </div>

      </div>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import router, { routeNames } from '@/router'
import { ReportType, Type } from '@/model/Type'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { DateConverter } from '@/client/DateConverter'
import Spinner from '@/components/generel/Spinner.vue'
import { getReportPage } from '@/service/workReportService'
import { Utils } from '../../client/utils'
import type { ReportPosition } from '@/model/ReportPosition'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { TenantSettings } from '../../stores/TenantSettings'

const props = defineProps<{
  invoiceId: string;
  projectId: string;
  locked: boolean;
  type: ReportType;
}>();

const pageSize = 10;
const totalPages = ref(0);
const positionsLoading = ref(false);
const tableReportPositions = ref<{position: ReportPosition, visible: boolean}[]>();
const offset = ref(0);

function dynamicStyle(cell: {position: ReportPosition, visible: boolean}) {
  if (cell.visible)                   return 'border-t border-l border-r border-primary rounded-t-md hover:cursor-pointer';
  if (props.locked && !cell.visible)  return 'unselectable-md px-0 py-0';
  if (!props.locked && !cell.visible) return 'selectable-md px-0 py-0';
  return '';
}

async function onEditReportPosition(id: string) {
  if (props.locked) return;
  await router.push({name: routeNames.EDIT_REPORT_POSITION, params: {id: id}, query: {subprojectId: props.invoiceId, type: props.type}})
}

async function onAddReportPosition() {
  await router.push({name: routeNames.CREATE_REPORT_POSITION, query: {subprojectId: props.invoiceId, type: props.type}})
}

function getQuantity(position: ReportPosition) {
  const firstUnit = position.entries[0].unitId;

  for (const entry of position.entries) if (entry.unitId != firstUnit) return 'divers'

  return `${position.entries.reduce((sum, entry) => sum + entry.quantity, 0)} ${Type.getUnit(firstUnit).abbreviation()}`;
}

function getUnitPrice(position: ReportPosition) {
  const firstPrice = position.entries[0].unitPrice
  const firstUnit = position.entries[0].unitId;

  for (const entry of position.entries) if (entry.unitId != firstUnit || entry.unitPrice != firstPrice) return 'divers'

  return `${firstPrice} ${TenantSettings.getCurrency()}/${Type.getUnit(firstUnit).abbreviation()}`;
}

function calculateTotalCosts(entries: ReportPositionEntry[]) {
  return entries.reduce((sum, entry) => sum + (entry.total ?? 0), 0);
}

function toggleVisibility(index: number): void {
  if (!tableReportPositions.value) return;
  for (let i = 0; i < tableReportPositions.value?.length; i++) {
    if (i == index) tableReportPositions.value[i].visible = !tableReportPositions.value[i].visible
    else tableReportPositions.value[i].visible = false;
  }
}

async function onNextPage() {
  if (offset.value + 1 >= totalPages.value) return;
  offset.value += 1;
  await loadReportPositions();
}

async function onPreviousPage() {
  if (offset.value == 0) return;
  offset.value -= 1;
  await loadReportPositions()
}

async function loadReportPositions(setLoading: boolean = false) {
  if (setLoading) positionsLoading.value = true;
  const response = await getReportPage(props.invoiceId, pageSize, offset.value, props.type as ReportType);
  positionsLoading.value = false;
  if (!response) return;
  totalPages.value = response.totalPages;
  tableReportPositions.value = response.page.map(w => {
    return {
      position: w,
      visible: false
    }
  })
}

watch(() => props.invoiceId, newVal => {
  if (!newVal) return;
  offset.value = 0;
  loadReportPositions(true);
}, {
  immediate: true
});

</script>

<style scoped>
  .row:hover > div {
    border-color: #001aea;
    background-color: white;
    box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.1),  /* Bottom shadow */
    inset 0 -6px 6px -6px rgba(0, 0, 0, 0.1); /* Top shadow */
  }

  .row > div {
    transition: box-shadow 0.15s ease, color 0.15s ease, border-color 0.15s ease;
  }

</style>