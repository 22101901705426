<template>

  <base-site :title="project?.name ?? ''"
             :subtitle-above="TenantSettings.isProjectOriented()
                ? (project?.customer ? Utils.getCustomerTitle(project.customer) : undefined)
                : undefined"
             :subtitle-below="TenantSettings.isCustomerOriented() ? (project?.customer ?Utils.getCustomerOneLineAddress(project.customer) : undefined) : undefined"
             :show-button="KeycloakService.isManager()" button-icon="pencil-simple"
             @subtitle-above-action="router.push({name: routeNames.CUSTOMER, params: {id: project?.customer.id}})"
             @button-action="TenantSettings.isProjectOriented() ? onEditProject() : onEditCustomer()">

    <div v-if="selectedItem" ref="subprojectListContainer" class="flex justify-between md:ml-0 ml-outerFrameBorder md:mr-0 mr-outerFrameBorder w-auto">
      <div class="flex flex-row w-full">

        <div class="w-positionSelectionBarSm md:w-positionSelectionBarMd lg:w-positionSelectionBarLg">
          <div class="flex flex-row w-full">
            <button :class="`selectable-md items-center p-6 sm:p-4 ${currentInvoice && currentInvoice.offer ? 'rounded-r-none border-r-0' : ''} ${dropdownVisible ? 'sm:rounded-b-none' : ''}`"
                    class="flex flex-row h-full whitespace-nowrap overflow-hidden text-ellipsis w-full sm:mb-0 mb-0" @click="dropdownVisible = !dropdownVisible">
              <phosphor-icon :icon="dropdownVisible ? 'caret-up' : 'caret-down'" size="text-iconSizeMedium" class="mr-3" />
              <div class="whitespace-nowrap overflow-hidden text-ellipsis">{{ currentInvoice ? currentInvoice.name : (selectedItem ? selectedItem.name : 'Rapport') }}</div>
            </button>


            <template v-if="currentInvoice && currentInvoice.offer">
              <button :class="`${selectedItem.id == currentInvoice.id ? 'selected-md' : 'selectable-md'} p-6 sm:p-4 rounded-none h-full border-r-primary flex flex-row items-center`" @click="onChangeItem(currentInvoice)">
                <phosphor-icon icon="clipboard-text" look="regular" class="mx-2 sm:mx-0" />
                <div class="hidden sm:block ml-3">Rapport</div>
              </button>
              <button v-if="currentInvoice.offer" :class="`${currentInvoice.offer.id == selectedItem.id ? 'selected-md' : 'selectable-md'} p-6 sm:p-4 rounded-l-none border-l-0 h-full flex flex-row items-center ${dropdownVisible ? 'sm:rounded-b-none' : ''}`" @click="onChangeItem(currentInvoice.offer)">
                <phosphor-icon icon="handshake" look="fill" class="mx-2 sm:mx-0" />
                <div class="hidden sm:block ml-3">Offerte</div>
              </button>
            </template>
          </div>

          <subproject-dropdown v-if="dropdownVisible" :items="itemsList ?? []" class="hidden sm:block w-full" @on-selection="onChangeItem" @on-click-outside="closeDropdown" />
        </div>

        <div v-if="KeycloakService.isManager()" class="sm:hidden flex selectable-md h-full text-grey-dark hover:text-primary items-center ml-4 p-4" @click="onCreateNewItem"><phosphor-icon icon="plus" class="mx-1"/></div>
        <div v-if="KeycloakService.isManager()" class="hidden sm:flex flex-row selectable-md h-full text-grey-dark mr-4 ml-auto" @click="onAddSubproject"><phosphor-icon icon="plus" class="mr-2"/><phosphor-icon icon="clipboard-text" look="regular" /></div>
        <div v-if="KeycloakService.isManager() && !TenantSettings.isTransport()" class="hidden sm:flex flex-row selectable-md h-full text-grey-dark hover:text-primary" @click="onAddOffer"><phosphor-icon icon="plus"  class="mr-2"/><phosphor-icon icon="handshake" look="fill" /></div>

      </div>
    </div>

    <div class="mb-4 w-full">
      <subproject-dropdown v-if="dropdownVisible" :items="itemsList ?? []" class="sm:hidden w-screen" @on-selection="onChangeItem" @on-click-outside="closeDropdown" />
    </div>

    <div :class="`flex-col sm:border border-grey-mid sm:rounded-md px-0 pb-0 pt-outerFrameBorder border-t sm:py-outerFrameBorder sm:px-outerFrameBorder mb-betweenElements ${isOffer(selectedItem) ? (invoicesLoading || offersLoading ? 'bg-none border-none' : 'bg-blue-mid') : (invoicesLoading || offersLoading ? 'bg-none border-none' : 'bg-white')}`">

      <spinner v-if="invoicesLoading || offersLoading" />

      <div v-else-if="itemsList && itemsList.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Elemente vorhanden</p>
        <div class="flex flex-row">
          <base-button v-if="KeycloakService.isManager() && !TenantSettings.isTransport()" class="mr-betweenElements" @click="onAddOffer()">
            Neue Offerte erstellen
          </base-button>
          <base-button v-if="KeycloakService.isManager()" @click="onAddSubproject()">
            Neuer Rapport erstellen
          </base-button>
        </div>
      </div>

      <div v-else-if="invoices && selectedItem">

        <base-heading class="flex flex-row items-center mx-outerFrameBorder sm:mx-0">
          <div class="sm:whitespace-nowrap sm:overflow-x-hidden sm:text-ellipsis min-h-9">{{ selectedItem.name }}</div>
          <div class="text-titleMedium ml-3 min-h-9">|</div>
          <phosphor-icon :icon="isSubproject(selectedItem) ? 'clipboard-text' : 'handshake'" :look="isSubproject(selectedItem) ? 'bold' : 'fill'" class="mx-3" />
          <div class="hidden md:block min-h-9">{{ isSubproject(selectedItem) ? 'Rapport' : 'Offerte' }}</div>
        </base-heading>

        <offer-header-card v-if="isOffer(selectedItem)"
                           :offer-id="selectedItem.id"
                           :copying-allowed="!!currentInvoice && !Type.getStatus(currentInvoice.statusId).locking"
                           @on-copy="onCopy"
                           @on-sync="onSync" />
        <subproject-header-card v-else-if="isSubproject(selectedItem)" :invoice="selectedItem" />

        <div v-if="isOffer(selectedItem)">
          <offer-report-card ref="workOfferCard"
                             id="workReport" class="mb-betweenElements flex-1 offset-scroll-into-view"
                             :offer-id="selectedItem.id"
                             :locked="offerStatusIsLocking(selectedItem.status)"
                             :type="ReportType.WORK"
                             :project-id="selectedItem.projectId"
                             :linked-invoice-id="currentInvoice?.id ?? undefined"
                             :copying-allowed="!!currentInvoice && !Type.getStatus(currentInvoice.statusId).locking"/>
          <offer-report-card ref="materialOfferCard" :offer-id="selectedItem.id"
                             id="materialReport" class="flex-1 offset-scroll-into-view mb-betweenElements"
                             :locked="offerStatusIsLocking(selectedItem.status)"
                             :type="ReportType.MATERIAL"
                             :project-id="selectedItem.projectId"
                             :linked-invoice-id="currentInvoice?.id"
                             :copying-allowed="!!currentInvoice && !Type.getStatus(currentInvoice.statusId).locking"/>
          <offer-discount-card id="discounts"
                               :offer-id="selectedItem.id"
                               :locked="offerStatusIsLocking(selectedItem.status)" />
        </div>

        <div v-else-if="isSubproject(selectedItem)" class="flex flex-col space-y-betweenElements">
          <report-card v-if="TenantSettings.isConstruction()"
                            id="workReport" class="flex-1 offset-scroll-into-view"
                            :invoice-id="selectedItem?.id"
                            :project-id="project?.id ?? ''"
                            :locked="Type.getStatus(selectedItem?.statusId).locking ?? false"
                            :type="ReportType.WORK"/>

          <transport-report-card v-if="TenantSettings.isTransport()"
                                 id="materialReport" class="flex-1 offset-scroll-into-view"
                                :invoice-id="selectedItem?.id"
                                :project-id="project?.id ?? ''"
                                :locked="Type.getStatus(selectedItem?.statusId).locking ?? false" />
          <report-card v-else id="materialReport" class="flex-1 offset-scroll-into-view"
                            :invoice-id="selectedItem?.id"
                            :project-id="project?.id ?? ''"
                            :locked="Type.getStatus(selectedItem?.statusId).locking ?? false"
                            :type="ReportType.MATERIAL" />

          <discount-card id="discounts" v-if="KeycloakService.isManager()"
                         :invoice-id="selectedItem?.id"
                         :locked="Type.getStatus(selectedItem?.statusId).locking ?? false"/>

        </div>

      </div>

    </div>

    <base-button v-if="KeycloakService.isManager() && !projectLoading && selectedItem" look="primary" class="md:mr-auto md:ml-0 mx-betweenElements sm:mb-0 mb-betweenElements w-max md:w-auto" @click="onGeneratePdf">
      {{ isSubproject(selectedItem) ? 'Rechungs PDF generieren' : 'Offerten PDF generieren' }}
    </base-button>

    <generate-invoice-modal ref="pdfModal" :is-invoice="isSubproject(selectedItem)" :subproject="isSubproject(selectedItem) ? selectedItem : undefined" :offer="isOffer(selectedItem) ? selectedItem : undefined" @on-close="onModalClose" />
    <create-descision-modal ref="decisionModal" :project-id="projectId" />

    <base-container v-if="!modalOpen && selectedItem && !TenantSettings.isTransport()" class="sm:hidden !bg-grey-mid sticky bottom-0 flex items-center justify-between h-overviewNav !py-7">
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('workReport')">
        <phosphor-icon icon="hammer" class="text-[calc(theme(fontSize.iconSize)+0.25rem)]" />
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('materialReport')">
        <phosphor-icon icon="package" />
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('discounts')">
        <phosphor-icon icon="percent" />
      </base-button>
      <base-button v-if="isOffer(selectedItem) && currentInvoice && !Type.getStatus(currentInvoice.statusId).locking" type="icon" look="secondary" class="overview-nav-btn" @click="onCopy">
        <phosphor-icon icon="copy" />
      </base-button>
    </base-container>

    <copy-modal ref="copyModal"
                :invoice-id="currentInvoice?.id ?? ''"
                @on-success="copySuccess"/>

  </base-site>

</template>

<script setup lang="ts">

import { onMounted, ref, toRaw, watch } from 'vue'
import type { Invoice, InvoiceRequest } from '@/model/Invoice'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { offerStatusIsLocking, ReportType, Type } from '@/model/Type'
import BaseButton from '@/components/base/BaseButton.vue'
import router, { routeNames } from '@/router'
import DiscountCard from '@/components/project/DiscountCard.vue'
import type Discount from '@/model/Discount'
import { useRoute } from 'vue-router'
import type Project from '@/model/Project'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import Spinner from '@/components/generel/Spinner.vue'
import GenerateInvoiceModal from '@/components/project/GenerateInvoiceModal.vue'
import { Utils } from '@/client/utils'
import { KeycloakService } from '@/service/keycloakService'
import { TenantSettings } from '@/stores/TenantSettings'
import TransportReportCard from '@/components/project/TransportReportCard.vue'
import type { Offer } from '@/model/Offer'
import SubprojectHeaderCard from '@/components/project/SubprojectHeaderCard.vue'
import OfferHeaderCard from '@/components/project/OfferHeaderCard.vue'
import OfferReportCard from '@/components/project/OfferReportCard.vue'
import CreateDescisionModal from '@/components/project/CreateDescisionModal.vue'
import SubprojectDropdown from '@/components/project/SubprojectDropdown.vue'
import BaseHeading from '@/components/base/BaseHeading.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import ReportCard from '@/components/project/ReportCard.vue'
import OfferDiscountCard from '@/components/project/OfferDiscountCard.vue'
import type { OfferPosition } from '@/model/OfferPosition'
import CopyModal from '@/components/project/CopyModal.vue'
import { useToast } from 'vue-toast-notification'
import { DateConverter } from '@/client/DateConverter'

const route = useRoute();
const projectId = route.params.id as string;
const preSelectedItemId = route.query.subproject as string;

const project = ref<Project>();
const invoices = ref<Invoice[]>([]);
const projectLoading = ref(true);
const invoicesLoading = ref(true);
const offersLoading = ref(true);
const currentInvoice = ref<Invoice>();
const offers = ref<Offer[]>([]);
const itemsList = ref<(Invoice | Offer)[]>();

const selectedItem = ref<Invoice | Offer>();

const discountsLoading = ref(true);

const dropdownVisible = ref(false);
const pdfModal = ref(GenerateInvoiceModal);
const modalOpen = ref(false);
const subprojectListContainer = ref<any>(null);
const $toast = useToast();
const decisionModal = ref(CreateDescisionModal);
const workOfferCard = ref(OfferReportCard);
const materialOfferCard = ref(OfferReportCard);

async function onSync(costs: number) {
  if (!currentInvoice.value) {
    $toast.error('Kein Rapport verknüpft');
    console.error('No liked invoice');
    return;
  }
  $toast.info('Synchronisation gestartet');
  const subprojectRequet: InvoiceRequest = {
    id: currentInvoice.value!.id,
    projectId: currentInvoice.value!.projectId,
    name: currentInvoice.value!.name,
    costLimit: costs,
    statusId: currentInvoice.value!.statusId,
    skonto: currentInvoice.value!.skonto ? currentInvoice.value!.skonto : 0,
    skontoExpiration: currentInvoice.value!.skontoExpiration ? currentInvoice.value!.skontoExpiration : 0,
    customFieldValues: currentInvoice.value!.customFieldValues,
    offerId: (selectedItem.value as Offer).id,
    vatType: currentInvoice.value!.vatType
  }
  const response = await API.updateDataObject<Invoice, InvoiceRequest>(PathSegment.INVOICES, subprojectRequet, 'Rapport');
  if (!response) return;
  const index = itemsList.value?.indexOf(currentInvoice.value);
  if (index && itemsList.value) {
    (itemsList.value[index] as Invoice).costLimit = costs;
  }
  $toast.success('Synchronisation abgeschlossen');
}

const copyModal = ref(CopyModal);
const openCopyModal = (offerPositions: OfferPosition[]) => {
  if (copyModal.value) {
    copyModal.value.openModal(offerPositions);
  }
};

function onCopy() {
  if (!workOfferCard.value || !materialOfferCard.value) {
    console.error('work or material offer card not present');
    return;
  }

  const checkedPositions = [...workOfferCard.value.emitChecked(), ...materialOfferCard.value.emitChecked()];
  openCopyModal(checkedPositions);
}

function copySuccess() {
  if (!workOfferCard.value || !materialOfferCard.value) {
    console.error('work or material offer card not present');
    return;
  }

  workOfferCard.value.checkAll(false);
  materialOfferCard.value.checkAll(false);
  $toast.success("Positionen kopiert");
}

watch(() => itemsList.value, async newVal => {
  discountsLoading.value = true;
  if (!newVal || newVal.length == 0) return;

  if (preSelectedItemId) {
    selectedItem.value = newVal.find(s => s.id == preSelectedItemId);
  }
  if (!selectedItem.value) {
    const item = newVal
      .filter(i => isSubproject(i) && i.offer)
      .map(s => (s as Invoice).offer)
      .find(o => o!.id == preSelectedItemId) as (Offer | undefined)
    if (item && item.linked) {
      selectedItem.value = newVal.find(i => isSubproject(i) && i.offer?.id == item.id);
      onChangeItem(item);
      return;
    }
  }
  if (!selectedItem.value)
    selectedItem.value = newVal[0];

  onChangeItem(selectedItem.value);
}, {
  immediate: true
});

function closeDropdown() {
  dropdownVisible.value = false;
}

async function loadProject() {
  const response = await API.wrapGet<Project>(API.getWithParameters(PathSegment.PROJECTS, projectId, new Map<string,string>([['includeCustomer', 'true']])), 'Projekt');
  if (!response) return;
  project.value = response;
  projectLoading.value = false;
}

async function loadInvoices() {
  const response = await API.wrapGet<Invoice[]>(API.get(PathSegment.PROJECTS, projectId, PathSegment.INVOICES), 'Rapporte');
  if (!response) return;
  invoices.value = response;
  invoicesLoading.value = false;
}

async function loadOffers() {
  const response = await API.wrapGet<Offer[]>(API.get(PathSegment.PROJECTS, projectId, PathSegment.OFFERS), 'Offerten');
  if (!response) return;
  offers.value = response;
  offersLoading.value = false;
}

function onChangeItem(item: Invoice | Offer) {
  if (isSubproject(item)) currentInvoice.value = item;
  else if (isOffer(item) && item.linked && isSubproject(selectedItem.value)) currentInvoice.value = selectedItem.value
  else currentInvoice.value = undefined;
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      subproject: item.id
    }
  });
  selectedItem.value = item;
  dropdownVisible.value = false;
}

function onChangeToOffer(subproject: Invoice) {
  currentInvoice.value = subproject;
  if (subproject.offer) onChangeItem(subproject.offer);
}

function onCreateNewItem() {
  if (TenantSettings.isTransport()) onAddSubproject();
  else openDecisionModal();
}

function mergeSubprojectsAndOffers() {
  const list: (Invoice | Offer)[] = [...invoices.value, ...offers.value]
  list.sort((a, b) => b.createdAt - a.createdAt);
  itemsList.value = list;
}

onMounted(async () => {
  await loadProject();
  await loadInvoices();
  await loadOffers();
  mergeSubprojectsAndOffers()
});

function onEditProject() {
  router.push({name: routeNames.EDIT_PROJECT, params: {id: projectId}});
}

function onEditCustomer() {
  router.push({name: routeNames.EDIT_CUSTOMER, params: {id: project.value?.customer.id}});
}

function onAddSubproject() {
  router.push({name: routeNames.CREATE_SUBPROJECT, query: {projectId: projectId}});
}

function onAddOffer() {
  router.push({name: routeNames.CREATE_OFFER, query: {projectId: projectId}});
}

const openModal = () => {
  if (pdfModal.value) {
    modalOpen.value = true;
    pdfModal.value.openModal();
  }
};

const openDecisionModal = () => {
  if (decisionModal.value) {
    modalOpen.value = true;
    decisionModal.value.openModal();
  }
};

function onModalClose() {
  modalOpen.value = false;
}

async function onGeneratePdf() {
  openModal();
}

function isSubproject(value: Invoice | Offer | undefined): value is Invoice {
  if (!value) return false;
  return 'costLimit' in value;
}

function isOffer(value: Invoice | Offer | undefined): value is Offer {
  if (!value) return false;
  return 'costs' in value;
}

const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'center'
  })
}

</script>

<style scoped>

.overview-nav-btn {
  @apply h-full self-stretch aspect-square p-0 bg-transaprent border-none hover:bg-transaprent hover:text-primary active:text-white active:bg-primary;
}

.offset-scroll-into-view {
  @apply scroll-my-[theme(spacing.headerHeight)];
}

</style>