<template>
  
  <base-container class="mb-betweenElements">

    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <p class="text-bodyMediumBold mb-2" :style="{ color: invoice.statusId ? Type.getStatus(invoice.statusId).displayColor : '#000000' }">
            {{ invoice && invoice.statusId ? Type.getStatus(invoice!.statusId).name : '' }}
          </p>
          <base-icon v-if="Type.getStatus(invoice?.statusId).locking"
                     class="ml-4 mb-2" size="text-iconSizeSmall" :style="{ color: invoice.statusId ? Type.getStatus(invoice.statusId).displayColor : '#000000' }">lock</base-icon>
        </div>
        <p class="text-bodyMediumBold">
          {{ Utils.formatCurrency(invoice?.currentCosts) }}
          {{ TenantSettings.getCurrency() }}
          {{ invoice?.costLimit > 0 ? `/ ${Utils.formatCurrency(invoice?.costLimit)} ${TenantSettings.getCurrency()}` : '' }}
          {{ subprojectCostLimitPercentage() }}
        </p>
        <p>Referenznummer: {{ invoice?.referenceNumber }}</p>
        <p v-for="fieldValue of invoice.customFieldValues" :key="fieldValue.id">
          {{ TenantFields.get(fieldValue.customFieldId)?.name }}: {{ fieldValue.value }}
        </p>
      </div>
      <base-button v-if="KeycloakService.isManager()" class="ml-auto mt-auto mb-auto rounded-lg" type="icon" look="secondary" @click="onEditSubproject()">
        <phosphor-icon icon="pencil-simple" />
      </base-button>
    </div>
  </base-container>
  
</template>

<script setup lang="ts">

import { Type } from '@/model/Type'
import { KeycloakService } from '@/service/keycloakService'
import { Utils } from '@/client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import type { Invoice } from '@/model/Invoice'
import router, { routeNames } from '@/router'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { TenantSettings } from '../../stores/TenantSettings'
import { TenantFields } from '../../stores/TenantFields'

const props = defineProps<{
  invoice: Invoice;
}>();

function onEditSubproject() {
  router.push({name: routeNames.EDIT_SUBPROJECT, params: {id: props.invoice?.id}});
}

function subprojectCostLimitPercentage(): string {
  if (!props.invoice || !props.invoice.costLimit || props.invoice!.costLimit == 0) return '';
  const percentage = (props.invoice!.currentCosts / props.invoice!.costLimit) * 100;
  return `(${percentage.toFixed(0)}%)`;
}

</script>

<style scoped>

</style>